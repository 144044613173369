var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"auto-row",class:{
           'auto-row-column':_vm.mode == 'column',
           ['auto-row-justify-'+_vm.justify]: _vm.justify != 'start',
           ['auto-row-align-items-'+_vm.items]: _vm.items != 'start',
           ['auto-row-'+_vm.wrap]: _vm.wrap != 'nowrap' && _vm.wrap !== true,
		   ['auto-row-wrap']:_vm.wrap === true || _vm.wrap == 'wrap',
        }},[_vm._t("default",null,{"parentName":"auto-row"})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }