<template>
   <div 
	    class="auto-row"
        :class="{
           'auto-row-column':mode == 'column',
           ['auto-row-justify-'+justify]: justify != 'start',
           ['auto-row-align-items-'+items]: items != 'start',
           ['auto-row-'+wrap]: wrap != 'nowrap' && wrap !== true,
		   ['auto-row-wrap']:wrap === true || wrap == 'wrap',
        }"
    >
        <slot parent-name="auto-row"></slot>
    </div>
</template>
<script>
    export default {
        name: 'auto-row',
        components: {},
        props: {
            mode:{
                type:String,
                default:"row",
            },
            justify:{
                type:String,
                default:"start",
            },
            items:{
                type:String,
                default:"start",
            },
            wrap:{
				type:[String, Boolean],
				default:"nowrap",
            },
        },
        provide(){
            return {
                getMode:this.getMode,
            };
        },
        data() {
            return {
               
            }
        },
        computed: { //计算属性

        },
        watch: {  //监听器

        },
        methods: {
            getMode(){
                return this.mode;
            },
            init(){
                let {
                    $slots,
                    $slots:{
                        default:defaultSlots = [],
                    },
                } = this;
                $slots.default = defaultSlots.filter(vnode => {
                    return  vnode.tag && /^vue-component-\d+-auto-col$/.test(vnode.tag);
                })
            },
        },
		beforeCreate(){ //在实例初始化之后被调用
			
		},
		created(){ //在实例创建完成后被立即调用
			this.init();
		},
		beforeMount(){ //在挂载开始之前被调用
			
		},
		mounted(){ //挂载到实例上去之后调用
			
		},
		beforeUpdate(){ //数据更新时调用，发生在虚拟 DOM 打补丁之前
			this.init();
		},
		updated(){ //由于数据更改导致的虚拟 DOM 重新渲染和打补丁，在这之后会调用该钩子

		},
		beforeDestroy(){ //实例销毁之前调用。在这一步，实例仍然完全可用
			
		},
		destroyed(){ //	Vue 实例销毁后调用。调用后，Vue 实例指示的所有东西都会解绑定，所有的事件监听器会被移除，所有的子实例也会被销毁
			
		},
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.auto-row{
    width:100%;
    display:flex;
    flex-wrap:nowrap;
}
.auto-row-column{
    flex-direction: column;
}

.auto-row-justify-start{
    justify-content: flex-start;
}
.auto-row-justify-end{
    justify-content: flex-end;
}
.auto-row-justify-center{
    justify-content: center;
}
.auto-row-justify-between{
    justify-content: space-between;
}
.auto-row-justify-around{
    justify-content: space-around;
}

.auto-row-align-items-start{
    align-items: flex-start;
}
.auto-row-align-items-end{
    align-items: flex-end;
}
.auto-row-align-items-center{
    align-items: center;
}
.auto-row-align-items-baseline{
    align-items: baseline;
}
.auto-row-align-items-stretch{
    align-items: stretch;
}

.auto-row-wrap{
    flex-wrap:wrap;
}
.auto-row-wrap-reverse{
    flex-wrap:wrap-reverse;
}
</style>
